import React from "react"

// import Layout from "../components/layout"
import Layout from '../layout/pl';

// import SEO from "../components/seo"

const NotFoundPage = (props) => (
  <Layout {...props}>
    {/* <SEO title="404: Not found" /> */}
    <div className="content">
      <h1>Nie znaleziono strony</h1>
      <p>Niestety ta strona nie istnieje. Powiadom obsługę strony o tym zdarzeniu.</p>
    </div>
  </Layout>
)

export default NotFoundPage
